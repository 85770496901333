<template>
    <div>
        <PageTitle title="身份注册" fixed backTo="/accountJudgment/0/0/0"></PageTitle>
        <div style="position: relative;width: 85%;margin: 15px auto 65px auto;">
            <p class="tips">请拍照并上传患者的身份证明</p>
            <List :width="100">
                <van-uploader v-model="formData.pictrueList1" :after-read="afterRead1" :max-count="1"
                              previewSize="120px">
                    <ListItem :leftWidth="35" :rightWidth="65">
                        <template v-slot:left>
                            <img src="../assets/identity-front.png"/>
                        </template>
                        <template v-slot:right>
                            <p style="font-size:13px;margin-top:10px">拍摄要求：</p>
                            <p>患者本有有效二代身份证<br>拍摄时确保，身份证边框完整，<br>字体清晰，亮度均匀</p>
                        </template>
                        <template v-slot:bottom>
                            <p>拍摄身份证人像面照片</p>
                        </template>
                    </ListItem>
                </van-uploader>
                <van-uploader v-model="formData.pictrueList2" :after-read="afterRead2" :max-count="1"
                              previewSize="120px">
                    <ListItem :leftWidth="35" :rightWidth="65">
                        <template v-slot:left>
                            <img src="../assets/identity-back.png"/>
                        </template>
                        <template v-slot:right>
                            <p style="font-size:13px;margin-top:10px">拍摄要求：</p>
                            <p>患者本有有效二代身份证<br>拍摄时确保，身份证边框完整，<br>字体清晰，亮度均匀</p>
                        </template>
                        <template v-slot:bottom>
                            <p>拍摄身份证国徽面照片</p>
                        </template>
                    </ListItem>
                </van-uploader>
            </List>
            <footer>
                <van-button type="primary" block style="margin-bottom:10px" @click="confirmUpload" :loading="btnLoading" loading-text="识别中...">确认上传</van-button>
                <van-button type="primary" block style="margin-bottom:10px" @click="fillInManually">手动填写</van-button>
            </footer>
        </div>
        <div class="bottomAdd">
            <p>因国家相关法律法规要求</p>
            <p>精神卫生网上诊疗，需实名认证</p>
            <p style="margin-bottom: 10px">谢谢配合</p>
        </div>

    </div>
</template>

<script>
    import PageTitle from '@/components/PageTitle.vue';
    import List from '@/components/home-page/List.vue';
    import ListItem from '@/components/home-page/ListItem.vue';

    import {GetCardInfoBase64, GetCardInfoBackBase64} from '@/api/user.js';//UploadFileBase64

    export default{
        components: {
            PageTitle,
            List,
            ListItem,
        },
        data(){
            return {
				btnLoading: false,
                formData: {
                    pictrueList1: [],
                    pictrueList2: []
                },
                filePath1: "",
                filePath2: "",
                postData: {
                    userName: "",
                    userNum: "",
                    userAddr: "",
                    userMz: "",
                    userSex: "",
                    userBirth: "",
                }
            }
        },
        methods: {
            afterRead1() {
                if (this.formData.pictrueList1.length > 0) {
                    this.addShow1 = 0;
                    let that = this;
                    for (let [index, file] of new Map(this.formData.pictrueList1.map((file, index) => [index, file]))) {
                        let fileObj = file.file;//原文件   file是我用vue-vant里的组件，里边有file(原文件)和content(base64)  其它写法自行百度，都是可以拿到原文件的
                       console.log(fileObj.size)
						if (fileObj && fileObj.size && fileObj.size > 1024 * 1024 * 3) {//判断是否大于3MB
                            let cal = 1024 * 1024 * 3 / fileObj.size; //计算比例，也可以自己定义比例 0.5等等
                            let reader = new FileReader();
                            reader.readAsDataURL(fileObj);
                            reader.onload = function () {
                                let image = new Image(); //新建一个img标签（不嵌入DOM节点，仅做canvas操作)
                                image.src = file.content; //file是我用vant里的组件，里有转好的base64格式content
                                image.onload = function () {    //图片加载完毕后再通过canvas压缩图片，否则图片还没加载完就压缩，结果图片是全黑的
                                    let canvas = document.createElement('canvas'), //创建一个canvas元素
                                        context = canvas.getContext('2d'),    //context相当于画笔，里面有各种可以进行绘图的API
                                        imageWidth = image.width * cal,    //压缩后图片的宽度
                                        imageHeight = image.height * cal    //压缩后图片的高度
                                    canvas.width = imageWidth    //设置绘图的宽度
                                    canvas.height = imageHeight    //设置绘图的高度
                                    //使用drawImage重新设置img标签中的图片大小，实现压缩。drawImage方法的参数可以自行查阅W3C
                                    context.drawImage(image, 0, 0, imageWidth, imageHeight)
                                    //使用toDataURL将canvas上的图片转换为base64格式
                                    fileObj = canvas.toDataURL('image/jpeg');
                                    that.formData.pictrueList1[index].content = fileObj;
                                }
                            }
                        }
                    }
                }
            },
            afterRead2() {
                if (this.formData.pictrueList2.length > 0) {
                    this.addShow1 = 0;
                    let that = this;
                    for (let [index, file] of new Map(this.formData.pictrueList2.map((file, index) => [index, file]))) {
                        let fileObj = file.file;//原文件   file是我用vue-vant里的组件，里边有file(原文件)和content(base64)  其它写法自行百度，都是可以拿到原文件的
                        if (fileObj && fileObj.size && fileObj.size > 1024 * 1024 * 3) {//判断是否大于3MB
                            let cal = 1024 * 1024 * 3 / fileObj.size; //计算比例，也可以自己定义比例 0.5等等
                            let reader = new FileReader();
                            reader.readAsDataURL(fileObj);
                            reader.onload = function () {
                                let image = new Image(); //新建一个img标签（不嵌入DOM节点，仅做canvas操作)
                                image.src = file.content; //file是我用vant里的组件，里有转好的base64格式content
                                image.onload = function () {    //图片加载完毕后再通过canvas压缩图片，否则图片还没加载完就压缩，结果图片是全黑的
                                    let canvas = document.createElement('canvas'), //创建一个canvas元素
                                        context = canvas.getContext('2d'),    //context相当于画笔，里面有各种可以进行绘图的API
                                        imageWidth = image.width * cal,    //压缩后图片的宽度
                                        imageHeight = image.height * cal    //压缩后图片的高度
                                    canvas.width = imageWidth    //设置绘图的宽度
                                    canvas.height = imageHeight    //设置绘图的高度
                                    //使用drawImage重新设置img标签中的图片大小，实现压缩。drawImage方法的参数可以自行查阅W3C
                                    context.drawImage(image, 0, 0, imageWidth, imageHeight)
                                    //使用toDataURL将canvas上的图片转换为base64格式
                                    fileObj = canvas.toDataURL('image/jpeg');
                                    that.formData.pictrueList2[index].content = fileObj;
                                }
                            }
                        }
                    }
                }
            },
            confirmUpload(){
                if (this.formData.pictrueList1.length == 0) {
                    this.$toast('请先上传身份证正面照片')
                }
                else if (this.formData.pictrueList2.length == 0) {
                    this.$toast('请先上传身份证反面照片')
                }
                else {
                    let that = this;
                    let file1 = this.formData.pictrueList1[0];
                    let postData1 = {
                        name: file1.file.name,
                        content: file1.content,
                        type: "1",
                        cardId: ""
                    };
                    let file2 = this.formData.pictrueList2[0];
                    let postData2 = {
                        name: file2.file.name,
                        content: file2.content,
                        type: "1",
                        cardId: ""
                    };
					this.btnLoading = true;
                    GetCardInfoBase64(postData1).then(
                        (result) => {
                            GetCardInfoBackBase64(postData2).then(
                                (time) => {
									this.btnLoading = false;
                                    if (time.sxrq > that.$util.moment().format('YYYYMMDD')) {
                                        that.$store.dispatch('pageData/clearData');//清空临时全局变量
                                        that.$store.dispatch('pageData/setData', result);//临时全局变量赋值身份证信息
                                        that.$router.push("/idCardConfirm");
                                    }
                                    else if (time.sxrq == '') {
                                        that.$toast('身份证背面信息未识别');
                                    }
                                    else {
                                        that.$toast('该身份证未在有效期');
                                    }
                                },
                                () => {
									this.btnLoading = false;
                                }
                            );
                        },
                        () => {
							this.btnLoading = false;
                        }
                    );
                }
            },
            fillInManually(){
                this.$store.dispatch('pageData/clearData');//清空临时全局变量
                this.$store.dispatch('pageData/setData', this.postData);//临时全局变量赋值身份证信息空值
                this.$router.push("/idCardConfirm");
            },
        }
    }
</script>

<style scoped="" lang="less">
    @import "../style/var.less";

    .van-uploader {
        display: block;
    }

    .tips {
        font-size: @font-size-lg;
        text-align: center;
        color: @green;
    }

    .right p {
        font-size: @font-size-sm;
        margin: 2px 0 2px;
    }

    .bottom p {
        border-top: 1px solid @green;
        width: 80%;
        margin: 10px 10%;
        text-align: center;
        font-size: @font-size-lg;
        padding-bottom: 10px;
    }

    footer {
        position: relative;
        text-align: center;
    }

    .bottomAdd {
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        background: white;
        p {
            color: @green;
            margin: 0;
            font-size: @font-size-sm;
        }
    }
</style>